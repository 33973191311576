<template>
  <div class="about-us">
    <div class="header">
      <div class="logo">
        <img src="@/assets/logo@2x.png" class="img">
      </div>
      <div class="title">猫嘀嘀</div>
    </div>
    <div class="content">
      猫嘀嘀APP是一款专为有融资需求的用户打造的线上工具，致力于为中小企业及个人用户提供精准、便捷、高效的融资渠道和专业服务。
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.about-us {
  .header {
    .logo {
      padding-top: 45px;
      text-align: center;
      .img {
        width: 92px;
        height: 85px;
      }
    }
    .title {
      text-align: center;
      padding-top: 10px;
      font-size: 16px;
      font-weight: bold;
      color: #2E3135;
    }
  }
  .content {
    padding: 60px 16px 0 16px;
    line-height: 20px;
    font-size: 14px;
    color: #666666;
  }
}
</style>
